import React, {memo} from "react"
import "./style.scss"
import Img from "gatsby-image"

import { Link } from "gatsby"
//import { GatsbyImage } from "gatsby-plugin-image"

function Tile(props) {
	const post = props.node
	const date = new Date(post?.publishDate)

	const baseDelay = props.baseDelay ? props.baseDelay : 0
	
  return (
		<div className="tile fs-85 c0 border-c5 border-top-off border-left-off t">
			<article className="tile-inner">
				<div className="c0 t image border-top-off border-left-off border-right-off border-bottom-off">
					{ post?.heroImage || props.defaultImage ?
						<a href={`${post?.link}`} className="nostrike" target="_blank" rel="noreferrer noopener">
							{/* <GatsbyImage
								image={post?.heroImage?.gatsbyImageData ? post?.heroImage?.gatsbyImageData : props.defaultImage.gatsbyImageData}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								alt={post?.heroImage?.description ? post?.heroImage?.description : ""}
							/> */}
							<Img fluid={post.heroImage?.fluid}
								imgStyle={{objectFit: 'contain'}}
								objectPosition='50% 50%'
								loading='lazy'
								fadeIn={true}
							/>
						</a>
					: null }
				</div>
				<div className="description c0 border-c5">
					<div className="post-title"><h2>{post?.title}</h2></div>
					{ post?.description ? post?.description.description : null }
				</div>
				<div className="button c0 t">
					<div className="button-inner c5 t border-c5">
						<a href={`${post?.link}`} className="btn no-effect" target="_blank" rel="noreferrer noopener"><span>Visit Website</span></a>
					</div>
				</div>
			</article>
		</div>
  )
}

export default memo(Tile)