import React from 'react'
import Tile from '../components/Aftercare/Tile'
import '../components/Aftercare/style.scss'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Img from "gatsby-image"
import heroimg from '../components/Aftercare/aftercare_hero.jpg'
// import { useInView } from 'react-intersection-observer'

function Custom(props) {

	const data = useStaticQuery(graphql`
	query AftercareOrganizations {
		allContentfulAftercareOrganization {
        edges {
          node {
						id
            title
            slug
            category {
              title
            }
						description {
							description
						}
						heroImage {
							description
							file {
								url
							}
              fluid (
                maxWidth: 1600,
                quality: 70,
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
						}
						link
          }
        }
      }
	  } 
  `)


	const maryland = data?.allContentfulAftercareOrganization?.edges?.filter(node => node.node?.category?.title === 'Maryland').map(prepPosts)
	const florida = data?.allContentfulAftercareOrganization?.edges?.filter(node => node.node?.category?.title === 'Florida').map(prepPosts)
	const nCali = data?.allContentfulAftercareOrganization?.edges?.filter(node => node.node?.category?.title === 'Northern California').map(prepPosts)
	const soCali = data?.allContentfulAftercareOrganization?.edges?.filter(node => node.node?.category?.title === 'Southern California').map(prepPosts)
	const nationWide = data?.allContentfulAftercareOrganization?.edges?.filter(node => node.node?.category?.title === 'Nationwide').map(prepPosts)


	function prepPosts(item,i) {
		return item ? (
			<Tile key={item.node.id} { ...item } baseDelay={i*125} />
		) : false
	}
	

  return (
    <main className={"main-aftercare"}>
      <SEO title={props?.pageContext.title ? props?.pageContext.title : "Aftercare"} description={props?.pageContext.description ? props?.pageContext.description.description : "1/ST Racing is proud to partner with the following Aftercare facilities dedicated to retraining and rehoming Thoroughbred racehorses."} />


      <section className="hero-aftercare">
        <div className="leftside">
          <img src={heroimg} />
        </div>
        <div className="rightside">
          <h1>Aftercare</h1>
          <p>1/ST Racing is proud to partner with the following Aftercare facilities dedicated to retraining and rehoming Thoroughbred racehorses.</p>
        </div>
      </section>

      <section className="aftercare">
        <div className={`block-custom flex-12 padd-top-2 padd-bottom-2 ${props.classes}`}>
          {props.anchor ? <div id={props.anchor} className="anchor"></div> : null}

          {maryland?.[0] ?
          <>
            <div className='separator c5 padd-half span-12 flex-12'>
              <h3>MARYLAND</h3>
            </div>
            <div className="posts-grid">
              {maryland}
            </div>
          </>
          : null }
          {florida?.[0] ?
          <>
            <div className='separator c5 padd-half span-12 flex-12'>
              <h3>FLORIDA</h3>
            </div>
            <div className="posts-grid">
              {florida}
            </div>
          </>
          : null }
          {nCali?.[0] ?
          <>
            <div className='separator c5 padd-half span-12 flex-12'>
              <h3>NORTHERN CALIFORNIA</h3>
            </div>
            <div className="posts-grid">
              {nCali}
            </div>
          </>
          : null }
          {soCali?.[0] ?
          <>
            <div className='separator c5 padd-half span-12 flex-12'>
              <h3>SOUTHERN CALIFORNIA</h3>
            </div>
            <div className="posts-grid">
              {soCali}
            </div>
          </>
          : null }
          {nationWide?.[0] ?
          <>
            <div className='separator c5 padd-half span-12 flex-12'>
              <h3>NATIONWIDE</h3>
            </div>
            <div className="posts-grid">
              {nationWide}
            </div>
          </>
          : null }

          {/* <pre>
            <code>{JSON.stringify(data, null, 2)}</code>
          </pre> */}
          
        </div>
      </section>

      <section className="liaisons-aftercare grid-12 padd-2">
        <div className="padd-2 span-6">
          <h2>1/ST</h2>
          <p>1/ST is committed to the care and safety of the horses who race and train at our tracks and training centers and is equally committed to a horse's care beyond their racing careers. 1/ST Aftercare Liaisons are dedicated to working with owners, trainers, and accredited aftercare organizations to assist with the safe and productive transition to second careers for horses who are no longer racing.</p>
          <p>If a horse is found to be in an unsafe situation, 1/ST Aftercare Liaisons will alert former racing connections and work together to come up with a safe transition for that horse.</p>
          <h3>Aftercare Liaisons</h3>
          <ul>
            <li>Gulfstream Park - Raina Gunderson <a href="mailto:raina.gunderson@gulfstreampark.com">raina.gunderson@gulfstreampark.com</a></li>
            <li>Santa Anita Park - Jeanne Schnell <a href="mailto:jeanne.schnell@santaanita.com">jeanne.schnell@santaanita.com</a></li>
            <li>Maryland Jockey Club - Georganne Hale <a href="mailto:georganne.hale@marylandracing.com">georganne.hale@marylandracing.com</a></li>
            <li>Golden Gate Fields - Emma Gurnick <a href="mailto:emma.gurnick@goldengatefields.com">emma.gurnick@goldengatefields.com</a></li>
          </ul>
        </div>
      </section>
    </main>
  )
}

export default Custom